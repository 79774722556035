import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import { MediaAssetInner } from '~/components/flexible/MediaAsset';
import { Category, Tag, ResourceGridImage } from '~/components/flexible/ResourcesGrid';
import { getBgClass } from '~/components/landing/Customers';
import { Facebook, Twitter, LinkedIn, YouTube, Instagram } from '~/components/elements/Icon';
import { MaybeLink, Button, ArrowLink, DownloadLink } from '~/components/elements/Link';
import Newsletter from '~/components/flexible/Newsletter';
import { ResourcesListing } from '~/components/flexible/Resources';
import { ArticleRenderer } from '~/components/layouts/ArticleRenderer';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { TextContent } from '~/components/flexible/TextContent';
import { InViewAnimator, InViewAnimatorBasicItem } from '~/components/elements/Animator';
import { MastheadStatsItem } from '~/components/flexible/MastheadStats';
import { ShareAndReadTime } from '~/templates/article';
import customerMedia from '~/assets/images/customer-media.jpg';
import { useCustomerStories } from '~/hooks/useCustomerStories';

export default function CustomerPage({
  data: { wpPage, resourcesPage, wpPost, wpCustomerStory, wp },
}: {
  data: { wpPage: { page: { post: { articleBlocks } } } };
}) {
  return (
    <Layout wp={wp}>
      <Seo post={wpCustomerStory} title={wpCustomerStory?.title} />
      <Masthead {...wpCustomerStory} />

      <div className="container">
        <div className="flex flex-col md:flex-row md:space-x-[50px] xl:space-x-[102px]">
          <div className="mb-12 md:mb-0 w-full md:w-[306px] md:mr-auto md:max-w-[636px] mx-auto md:ml-0 md:max-w-none">
            <Sidebar {...wpCustomerStory?.postTypeCustomerStory} />
          </div>
          <div className="md:w-[792px] flex-1 mb-12">
            <section className="xl:-mt-8">
              <ArticleRenderer
                prefix="CustomerStory_Posttypecustomerstory_ArticleBlocks_"
                content={wpCustomerStory?.postTypeCustomerStory?.articleBlocks}
              />
            </section>
          </div>
        </div>
        <Newsletter />
        <div className="max-w-[819px] mx-auto">
          <RelatedCustomers current={wpCustomerStory} style="two-col" related relatedCustomers />
        </div>
      </div>
      <div className="mt-32">
        <LayoutRenderer
          prefix="Page_Posttypepage_Sections"
          sections={resourcesPage?.postTypePage?.sections?.filter(
            (section) => section?.fieldGroupName === 'Page_Posttypepage_Sections_ReusableBlock'
          )}
        />
      </div>
    </Layout>
  );
}

export const RelatedCustomers = ({ current }) => {
  const customerStories = useCustomerStories();
  let i = 0;
  let relatedCustomerStories = customerStories.filter((item) => {
    if (item.title === current.title) {
      return false;
    } else {
      i++;
    }
    return i < 3;
  });
  return (
    <section className={`section section-tight space-y-16`}>
      <div
        className={`flex flex-col sm:flex-row space-y-4 sm:space-y-0 items-start sm:items-center border-b border-b-strokeGrey pb-5`}
      >
        <h3 className={'t-36'}>Related Customer Stories</h3>
      </div>

      <InViewAnimator
        className={`container grid-cols-1 sm:grid-cols-2 grid gap-y-10  md:gap-y-16 sm:gap-x-12`}
        inner={(controls) => (
          <>
            {relatedCustomerStories.map((item, i) => {
              return (
                <MaybeLink key={i} link={{ url: item.uri }} className="space-y-6">
                  <div className={`border-default-grid aspect-w-10 aspect-h-7 ${getBgClass(item)}`}>
                    <InViewAnimatorBasicItem
                      controls={controls}
                      delay={(i + 1) * 0.3}
                      prop="x"
                      start={50}
                      className="flex items-center justify-center"
                    >
                      <MediaAssetInner
                        className="w-full h-full max-w-[80%] max-h-[80%] mx-auto object-contain"
                        type="image"
                        image={item?.postTypeCustomerStory?.image}
                      />
                    </InViewAnimatorBasicItem>
                  </div>
                  <div className="space-y-4">
                    <h4 className="t-24">{item.postTypeCustomerStory?.mastheadHeading}</h4>
                    <ArrowLink link={{ title: 'Read more' }} />
                  </div>
                </MaybeLink>
              );
            })}
          </>
        )}
      />
    </section>
  );
};

export const Masthead = ({ postTypeCustomerStory, uri }) => {
  const { mastheadHeading, mastheadContent, mastheadLogo, mastheadImage, mastheadStats, mastheadTechStack, tag } =
    postTypeCustomerStory;

  const imageClasses = ['relative md:flex-1 md:pl-12'];
  const mainClasses = [
    'max-w-[613px] relative flex-1 mx-auto md:mx-0 pt-8 md:pt-12 lg:pt-24 lg:pb-24 xl:pt-36 xl:pb-28 items-start',
  ];
  let contentMaxWidth = '546';

  return (
    <InViewAnimator
      className="section-header-padding overflow-hidden bg-lightGrey mb-10 md:mb-[134px]"
      inner={(controls) => (
        <>
          <div className="border-b border-strokeGrey">
            <div className="container items-center space-y-10 md:flex md:justify-between md:space-y-0">
              <div className={mainClasses.join(' ')}>
                <div className="space-y-12">
                  <div className="space-y-10">
                    <InViewAnimatorBasicItem
                      controls={controls}
                      delay={0}
                      prop="y"
                      className="flex items-center md:items-start"
                    >
                      {mastheadLogo && (
                        <div className="relative inline-block w-auto py-5 mx-auto bg-white md:mx-0 px-7 border-small-grid">
                          <MediaAssetInner className="w-[105px]" type="image" image={mastheadLogo} />
                        </div>
                      )}
                    </InViewAnimatorBasicItem>
                    <InViewAnimatorBasicItem controls={controls} delay={0.3} prop="y">
                      <TextContent
                        innerOnly={true}
                        headingSize="52"
                        centered={false}
                        isMasthead={true}
                        linkStyle="button"
                        subheadingStyle="plain"
                        centered={false}
                        heading={mastheadHeading}
                        content={mastheadContent}
                        contentOpacity={90}
                        visualControls={{
                          restrictWidth: {
                            content: contentMaxWidth,
                          },
                        }}
                      />
                    </InViewAnimatorBasicItem>
                  </div>
                  <InViewAnimatorBasicItem
                    controls={controls}
                    delay={0.4}
                    prop="x"
                    className="max-w-[394px] mx-auto md:mx-0 space-y-5"
                  >
                    <LineHeading masthead heading={mastheadTechStack?.heading} />
                    {mastheadTechStack?.logos && (
                      <div className="flex items-center justify-center w-full space-x-4 md:justify-start lg:space-x-8">
                        {mastheadTechStack?.logos?.map((logo, i) => {
                          if (!logo?.logo) return null;
                          return (
                            <MediaAssetInner
                              key={i}
                              className="max-w-[80px] md:max-w-[120px] h-[44px]"
                              type="image"
                              image={logo?.logo}
                            />
                          );
                        })}
                      </div>
                    )}
                  </InViewAnimatorBasicItem>
                </div>
              </div>
              <div className={imageClasses.join(' ')}>
                <InViewAnimatorBasicItem controls={controls} delay={0.4} prop="x" start={-50}>
                  <MediaAssetInner
                    className="w-[85%] max-h-[500px] mx-auto md:mx-0 md:w-full relative"
                    type="image"
                    image={mastheadImage}
                  />
                </InViewAnimatorBasicItem>
              </div>
            </div>
            <div className="relative py-8 pt-12 lg:py-24 lg:pt-0">
              <div className="container grid gap-6 text-center md:grid-cols-3 gap-y-4 xl:gap-24">
                {mastheadStats?.map((stat, i) => (
                  <InViewAnimatorBasicItem key={i} controls={controls} delay={0.2 + i / 6} duration={0.5}>
                    <MastheadStatsItem {...stat} duration={2.2} />
                  </InViewAnimatorBasicItem>
                ))}
              </div>
            </div>
          </div>
          <div className="bg-white border-b border-b-strokeGrey">
            <div className="container">
              <ShareAndReadTime uri={uri} customer tag={tag} showRead />
            </div>
          </div>
        </>
      )}
    />
  );
};

export const Sidebar = ({ sidebar }) => {
  return (
    <div className="lg:sticky md:top-[90px] bg-fadedBlue p-[34px] space-y-7">
      {sidebar?.heading && (
        <div className="px-2 py-1 font-semibold text-center text-white bg-black">
          <div className="uppercase t-12">{sidebar.heading}</div>
        </div>
      )}
      <div className="space-y-8">
        {sidebar?.industry && (
          <div className="space-y-1.5">
            <LineHeading heading="Industry" />
            <div className="t-16">{sidebar.industry}</div>
          </div>
        )}
        {sidebar?.companySize && (
          <div className="space-y-1.5">
            <LineHeading heading="Company Size" />
            <div className="t-16">{sidebar.companySize}</div>
          </div>
        )}
        {sidebar?.keyPainPoints && (
          <div className="space-y-[14px]">
            <LineHeading heading="Key Pain Points" />
            <ul className="space-y-3">
              {sidebar?.keyPainPoints?.map((point, i) => (
                <li key={i} className="flex space-x-3 text-black transition-colors duration-200 ease-in-out t-15">
                  <div className="w-[5px] h-[5px] bg-black mt-[8px]" />
                  <div className="flex-1 leading-5">{point.text}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-5">
        {sidebar?.link && (
          <MaybeLink
            link={sidebar?.link}
            className="flex w-full space-x-3 transition-colors duration-200 ease-in-out t-16 fw-link"
          >
            <Button link={sidebar?.link} buttonStyle="black" size="tight" />
          </MaybeLink>
        )}
        {sidebar?.download && (
          <MaybeLink
            link={sidebar?.download}
            className="flex w-full space-x-3 transition-colors duration-200 ease-in-out t-16 fw-link"
          >
            <DownloadLink link={sidebar?.download} />
          </MaybeLink>
        )}
      </div>
    </div>
  );
};

export const LineHeading = ({ heading, masthead }) => {
  return (
    <div className="flex items-center space-x-3 font-medium uppercase t-12">
      {masthead && <div className="flex-1 block h-px bg-black bg-opacity-10 md:hidden"></div>}
      <div className="whitespace-nowrap">{heading}</div> <div className="flex-1 h-px bg-black bg-opacity-10"></div>
    </div>
  );
};

export const MastheadAsset = ({ previewImage, mastheadImage }) => {
  return (
    <MediaAssetInner
      className="mx-auto"
      type="image"
      image={mastheadImage ? mastheadImage : previewImage && previewImage}
    />
  );
};

export const pageQuery = graphql`
  query Customer($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    resourcesPage: wpPage(id: { eq: "cG9zdDozMzU=" }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wpCustomerStory(id: { eq: $id }) {
      ...GeneratedWpCustomerStory
    }
    wp {
      ...GeneratedWp
    }
  }
`;
